// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Navbar.css */
.navbarlinks {
  border: 1px solid black;
  color: black;
    padding: 1em;
    height: 40vh;
    margin-top: 120px;
    position: relative;
    top: 85px;
  }
  
  .navbarlinks * {
    color: black;
  }
  
  .navbarlinks ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    background-color: black;
  }
  
  .navbarlinks li {
    display: inline;
  }
  
  .navbarlinks a {
    color: white;
    text-decoration: none;
  }
  
  .navbarlinks a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/NavbarLinks.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,uBAAuB;EACvB,YAAY;IACV,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,6BAA6B;IAC7B,SAAS;IACT,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["/* src/components/Navbar.css */\r\n.navbarlinks {\r\n  border: 1px solid black;\r\n  color: black;\r\n    padding: 1em;\r\n    height: 40vh;\r\n    margin-top: 120px;\r\n    position: relative;\r\n    top: 85px;\r\n  }\r\n  \r\n  .navbarlinks * {\r\n    color: black;\r\n  }\r\n  \r\n  .navbarlinks ul {\r\n    list-style: none;\r\n    padding: 0;\r\n    display: flex;\r\n    justify-content: space-around;\r\n    gap: 10px;\r\n    background-color: black;\r\n  }\r\n  \r\n  .navbarlinks li {\r\n    display: inline;\r\n  }\r\n  \r\n  .navbarlinks a {\r\n    color: white;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .navbarlinks a:hover {\r\n    text-decoration: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
