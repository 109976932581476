// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .fileButton{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
.subjButton{
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/styling/File.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;AACF;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["  .fileButton{\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 30px;\r\n  }\r\n.subjButton{\r\n    display: flex;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
