import '../build quiz btn/BtnSubj.css';

function BtnSubject() {
  return (
    <div>
 
    </div>
  );
}

export default BtnSubject;