// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.url-input-container {
    width: 100%;
    max-width: 600px;
    padding: 1px;
  }
  
  .url-input {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the element's total width */
    outline: none;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .url-input:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a slight shadow for visual feedback */
  }
  
  .url-input:invalid {
    border-color: #dc3545; /* Change border color if the URL is invalid */
  }
  .subjButton{
    display: flex;
    justify-content: center;
}
.URLbtn{
  display: flex;
  justify-content: center;
  margin-top: 10px;

}`, "",{"version":3,"sources":["webpack://./src/components/styling/URL.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB,EAAE,4DAA4D;IACpF,aAAa;IACb,sEAAsE;EACxE;;EAEA;IACE,qBAAqB,EAAE,iCAAiC;IACxD,0CAA0C,EAAE,4CAA4C;EAC1F;;EAEA;IACE,qBAAqB,EAAE,8CAA8C;EACvE;EACA;IACE,aAAa;IACb,uBAAuB;AAC3B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;;AAElB","sourcesContent":[".url-input-container {\r\n    width: 100%;\r\n    max-width: 600px;\r\n    padding: 1px;\r\n  }\r\n  \r\n  .url-input {\r\n    width: 50%;\r\n    padding: 12px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    box-sizing: border-box; /* Include padding and border in the element's total width */\r\n    outline: none;\r\n    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\r\n  }\r\n  \r\n  .url-input:focus {\r\n    border-color: #007bff; /* Change border color on focus */\r\n    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a slight shadow for visual feedback */\r\n  }\r\n  \r\n  .url-input:invalid {\r\n    border-color: #dc3545; /* Change border color if the URL is invalid */\r\n  }\r\n  .subjButton{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.URLbtn{\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-top: 10px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
